.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 150px;
}
.contact-container .contact-title {
  font-size: 3rem;
  font-weight: 600;
  color: black;
  margin-bottom: 1rem;
  text-transform: uppercase;
  margin-top: 2rem;
}
.contact-container .contact-description {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  margin-bottom: 1rem;
}
.contact-container .contact-block {
  display: flex;
  width: 100%;
}
.block-1 {
  width: 40%;
  background-color: #f7f7f7;
  padding: 20px 25px;
  margin-right: 25px;
  border-top: 4px solid #16aa65;
  border-bottom: 4px solid #16aa65;
  height: 500px;
}
.block-1 .address,
.block-1 .email,
.block-1 .phone {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.block-1 .address i,
.block-1 .email i,
.block-1 .phone i {
  font-size: 19px;
  min-width: 50px;
  height: 50px;
  color: #16aa65;
  background-color: #eee;
  text-align: center;
  border-radius: 50%;
  line-height: 50px;
  margin-right: 15px;
}
.block-1 .address i:hover,
.block-1 .email i:hover,
.block-1 .phone i:hover {
  background-color: #16aa65;
  color: #f7f7f7;
}
.block-1 .address .address-details,
.block-1 .email .address-details,
.block-1 .email .block-1 .email .email-details,
.block-1 .email .block-1 .phone .phone-details,
.block-1 .phone .block-1 .email .phone-details,
.block-1 .phone .address-details,
.block-1 .phone .block-1 .email .email-details,
.block-1 .email .block-1 .phone .email-details,
.block-1 .phone .block-1 .phone .phone-details,
.block-1 .address .email .email-details,
.block-1 .email .address .email-details,
.block-1 .address .phone .phone-details,
.block-1 .phone .address .phone-details {
  color: black;
}
.block-1 .address .address-details p:first-child,
.block-1 .email .address-details p:first-child,
.block-1 .email .block-1 .email .email-details p:first-child,
.block-1 .email .block-1 .phone .phone-details p:first-child,
.block-1 .phone .block-1 .email .phone-details p:first-child,
.block-1 .phone .address-details p:first-child,
.block-1 .phone .block-1 .email .email-details p:first-child,
.block-1 .email .block-1 .phone .email-details p:first-child,
.block-1 .phone .block-1 .phone .phone-details p:first-child,
.block-1 .address .email .email-details p:first-child,
.block-1 .email .address .email-details p:first-child,
.block-1 .address .phone .phone-details p:first-child,
.block-1 .phone .address .phone-details p:first-child {
  font-size: 1.5rem;
}
.block-1 .address .address-details p:last-child,
.block-1 .email .address-details p:last-child,
.block-1 .email .block-1 .email .email-details p:last-child,
.block-1 .email .block-1 .phone .phone-details p:last-child,
.block-1 .phone .block-1 .email .phone-details p:last-child,
.block-1 .phone .address-details p:last-child,
.block-1 .phone .block-1 .email .email-details p:last-child,
.block-1 .email .block-1 .phone .email-details p:last-child,
.block-1 .phone .block-1 .phone .phone-details p:last-child,
.block-1 .address .email .email-details p:last-child,
.block-1 .email .address .email-details p:last-child,
.block-1 .address .phone .phone-details p:last-child,
.block-1 .phone .address .phone-details p:last-child {
  font-size: 14px;
}
.block-1 .map {
  height: 210px;
}
.block-1 .map iframe {
  width: 100%;
  height: 100%;
}
.block-2 {
  width: 60%;
  padding: 20px 25px;
  background-color: #f7f7f7;
  border-top: 4px solid #16aa65;
  border-bottom: 4px solid #16aa65;
  height: 500px;
}
form .form-row {
  display: flex;
  justify-content: space-between;
}
form .form-row .form-group input {
  width: 280px;
}
form .form-group {
  display: flex;
  flex-direction: column;
}
form .form-group label {
  color: #222;
  margin-bottom: 5px;
}
form .form-group input,
form .form-group textarea {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  color: #444;
}
form .form-group input:focus,
form .form-group textarea:focus {
  border: 1px solid #16aa65;
}
form .form-group textarea {
  height: 150px;
}
form .btn-container {
  display: flex;
  justify-content: center;
}
form .btn-container button {
  padding: 10px 25px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #16aa65;
  border: none;
  outline: none;
  color: #f7f7f7;
  border-radius: 5px;
  margin-top: 10px;
  opacity: 0.9;
  transition: opacity 0.3s ease-in;
}
form .btn-container button:hover {
  background-color: #124e7f;
  opacity: 1;
}
.success-page {
  margin: 15rem 1rem 15rem 1rem;
}

.success-message {
  font-size: 3rem;
}

.leadership-title {
  margin: 2rem 0 2rem 0;
}
@media (max-width: 1300px) {
  .form-row .form-group input {
    width: 225px !important;
  }
}
@media (max-width: 1100px) {
  .contact-container {
    margin: 10px 30px;
  }
  .form-row .form-group input {
    width: 200px !important;
  }
}
@media (max-width: 800px) {
  .contact-container {
    margin: 10px 25px;
  }
  .contact-block {
    flex-direction: column;
    justify-content: center !important;
    width: 100%;
  }
  .form-row .form-group input {
    width: 280px !important;
  }
  .block-1,
  .block-2 {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 660px) {
  .block-1,
  .block-2 {
    height: auto;
  }
  .form-row {
    flex-direction: column;
  }
  .form-row .form-group input {
    width: 100% !important;
  }
}
