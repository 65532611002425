video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}


.heroimage{
  display: none;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  margin: 0;
  font-size: 70px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
  background: #1888ff;
  border: none;
  color: white;
}
.hero-btns .btn:hover {
  color: black;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container{

box-shadow: none;
margin:0;
height: 0%;
    
  }
  .hero-container > h1 {
    font-size: 45px;
    position: absolute;
  top: 55%;
  right: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
   

  }

  .hero-container > p {
    font-size: 25px;
    position: absolute;
    top: 48%;
    left: 65%;
    right: -65%;
    transform: translate(-50%, -50%);
  }
  .hero-btns{
 margin: 0;
 margin-top: -13rem;
    top: 70%;
    left: 50%;
    
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
    
  }

  .btn {
    width: 100%;
  }
  video{
    display: none;
  }
  .heroimage{
    display: block;
     height: 80vh;
     width: 100%;
  }
}
