.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 75px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: grey;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #16aa65;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

/* @media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
} */

@media screen and (max-width: 960px) {
  .dropdown-menu {
    width: 200px;
    margin-top: 4.5rem;

    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    list-style: none;
    text-align: start;
  }
}
