.act-deck {
  display: flex;
  margin: 1rem 2rem 2rem 2rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
  
}
.act-h1 {
  margin: 0;
  margin-top: 2rem;
  font-size: 4.5rem;
  color: #16aa65;
}

.act-card {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  justify-content: center;
  width: 22rem;
  height: 25rem;
  border-radius: 2 px;
  border-top: 4px solid #16aa65;
  border-bottom: 4px solid #16aa65;
  background-color: #f7f7f7;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.act-card-image {
  margin: 1rem;
  width: 20rem;
  height: 17rem;
}
.act-card-title {
  display: flex;
  justify-content: center;
}
.act-p {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.act-h3 {
  font-size: 1.35rem;
  margin-bottom: 1rem;
}
@media (max-width: 660px) {
  .act-deck {
    flex-direction: column;
    align-items: center;
  }
  .act-card {
    margin: 1rem;
    width: 20rem;
  }
  .act-card-image {
    margin: 1rem;
    width: 18rem;
    height: 17rem;
  }
}
