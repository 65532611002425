.lodging-deck {
  display: flex;
  flex-direction: column;
  margin: 1rem;
 
  justify-content: space-around;
  align-items: center;
}

.lodging-card {

 width: 60%;
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 border-radius: 20px;
 margin-bottom: 2rem;
 background-color: #f7f7f7
 
 
}
.lodging-split{
 margin: 1rem 2rem 1rem 2rem;
}

.lodging-title {
  margin-bottom: 1rem;
  
 
}

.title-h1 {
  margin: 0;
  text-align: left;
}

.lodging-image {
  width: 400px;
  height: 350px;
  object-fit: cover;
  border-radius: 20px;
  margin: 1rem;
  
}

.lodging-description {
  border-top: 2px solid #16aa65;
}
.lodging-p {
  display: flex;
  justify-content: center;
margin-bottom: 10px;
margin-top: 10px;
  
 
}
.lodging-h1 {
  margin: 0;
  margin-top: 2rem;
  font-size: 4.5rem;
  color: #16aa65;
}

.lodging-h3 {
  margin: 1rem 4rem 4rem 4rem;
  line-height: 2rem;
  font-size: 1.2rem;
  border-radius: 4px;
  padding: 2rem;
  background-color: #f6f6f6;
}

@media (max-width: 660px) {
  .lodging-deck {
    flex-direction: column;
    align-items: center;
  }

  .lodging-card {
    border: 1px solid black;
  
  width: 95%;
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 border-radius: 20px;
 margin-bottom: 1rem;
  }

  .lodging-image {
    width: 92%;
    height: 92%;
    object-fit: cover;
    border-radius: 20px;
    margin: 1rem;
  }
}
