.dona-main {
  margin: 4rem 5rem 3rem 5rem;
}

.dona-h1 {
  margin: 0;
  margin-top: 2rem;
  font-size: 4.5rem;
  color: #16aa65;
  font-weight: 600;
}

.donation-deck {
  margin: 3rem 4rem 14rem 4rem;
  display: flex;
  justify-content: space-evenly;
}
.dona-card-one {
  height: 20rem;
  width: 25rem;
  margin-bottom: 2rem;
  border-radius: 4px;
}
.dona-card-two {
  padding-bottom: 5rem;
  width: 25rem;
  margin-bottom: 2rem;
  font-family: sans-serif;
  border: 1px solid lightgray;
}
.dona-paypal{
  display: flex;
  justify-content: center;
  align-items:center;
  margin-top: 3rem;
}

.dona-h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  background-color: #2ecc71;
  color: white;

  border-bottom: 1px solid lightgray;
  padding: 0.6rem;
}
.dona-mail {
  margin-top: 2rem;
}

.dona-h2 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  background-color: #2ecc71;
  color: white;
}



.dona-h4 {
  margin: 1rem 5rem 1rem 5rem;
  font-size: 1.3rem;
  line-height: 3rem;
}
@media (max-width: 660px) {
  .donation-deck {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0rem 2rem 0 2rem;
  }
  .dona-h1 {
    font-size: 3.5rem;
    margin-bottom: 2rem;
    color: #16aa65;
  }

  .dona-card-one {
    height: 18rem;
    width: 22rem;
    margin-bottom: 12rem;
  }

  .dona-card-two {
   
    width: 22rem;
  }

  .dona-h4 {
    margin: 0;
    margin-bottom: 2rem;
    font-size: 1rem;
  }
}
