.card-deck {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  margin: 3rem 4rem 3rem 4rem;
}

.card {
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 2px;
}
.image {

  width: 15rem;
  height: 15rem;
  
  object-fit: cover;
  margin-bottom: 1rem;
}

.text {
  display: flex;

  flex-direction: column;
  align-items: center;
}
@media (max-width: 660px) {
  .card-deck {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem 2rem 2rem;
  }

  .card {
    margin: 1rem;
  }

  .image {
    display: flex;
  
    object-fit: cover;
    width: 15rem;
    height: 15rem;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .card-deck {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem 2rem 2rem;
  }

  .card {
    margin: 1rem;
  }

  .image {
    width: 19rem;
    height: 15rem;
  }
}
