.about-parent {
  margin: 3rem;
}

.about-title {
  font-size: 4rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  color: #16aa65;
  margin-top: 0;
}
.about-sections {
  display: flex;
  margin: 2rem;
  justify-content: space-evenly;
}
.about-one {
  padding: 0;
  background-color: #f7f7f7;
  border-top: 4px solid #16aa65;
  border-bottom: 4px solid #16aa65;
}

.about-two {
  width: 60%;
  padding: 1rem;
  margin-left: 2rem;
  background-color: #f7f7f7;
  border-top: 4px solid #16aa65;
  border-bottom: 4px solid #16aa65;
}

.about-three {
  width: 60%;
  padding: 1rem;
  margin-left: 2rem;
  background-color: #f7f7f7;
  border-top: 4px solid #16aa65;
  border-bottom: 4px solid #16aa65;
}

.about-four {
  width: 60%;
  border: 1px solid black;
  padding: 10rem;
  margin: 1rem;
}
.about-image {
  object-fit: contain;
  width: 40rem;
  height: 40rem;
  margin: -4rem 2rem -4rem 2rem;
}

.about-h3 {
  font-size: 1.35rem;
  margin-bottom: 1rem;
}

.mission-title {
  color: #16aa65;
  font-size: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mission-statment-para {
  font-size: 1.1rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}
.about-image-two {
  object-fit: contain;
  width: 40rem;
  height: 40rem;
  margin: -7rem 2rem -7rem 2rem;
}

@media (max-width: 660px) {
  .about-parent {
    margin: 1rem;
    box-sizing: 0;
  }

  .about-sections {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 2rem 0;
    align-items: center;
  }

  .about-title {
    font-size: 3rem;
    margin: 0 0 2rem 0;
  }

  .about-one {
    display: none;
  }
  .about-two {
    width: 90%;
    margin: 0;
  }
  .about-three {
    width: 90%;
    margin: 0;
  }
  .about-image {
    display: none;
  }

  .about-image-two {
    display: none;
  }
  .mission-title {
    font-size: 3rem;
    margin: 0 0 2rem 0;
  }
  .mission-statment-para {
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.1rem;
  }
}
