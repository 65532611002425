.location-map {
  width: 65rem;
  height: 45rem;
  margin: 2rem;
  border-radius: 5px;
  margin-bottom: 5rem;
}

.location-title {
  margin: 0;
  margin-top: 2rem;
  font-size: 4rem;
  color: #16aa65;
}
.location-h3 {
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 2rem;
}

@media (max-width: 660px) {
  .location-h3 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .location-map {
    width: 20rem;
    height: 20rem;
    margin: 2rem;
    border-radius: 5px;
    margin-bottom: 5rem;
  }
}
